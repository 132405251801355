import React from 'react';
import Img from 'gatsby-image/withIEPolyfill';

const AssetZenImage = (props) => {
  const { 
    id,
    width, 
    height, 
    quality, 
    sizes,
    srcSetBreakpoints, 
    ...other
  } = props;
  let aspectRatio = 0;

  if( height ) {
    aspectRatio = width / height;
  }

  function getSizeQuery( width ) {
    const params = [
      `w:${width}`,
    ];
    
    if ( aspectRatio ) {
      params.push(`h:${Math.round(width / aspectRatio)}`);
    }
    
    return params.join('/');
  }

  return (
    <Img fluid={{
        aspectRatio,
        // base64: '',
        sizes: sizes || `(max-width: ${width}px) 100vw, ${width}px`,
        src: `https://i.assetzen.net/i/${id}/${getSizeQuery( width )}/q:${quality}.jpg`,
        srcSet: srcSetBreakpoints.map( breakpoint => 
          `https://i.assetzen.net/i/${id}/${getSizeQuery( breakpoint )}/q:${quality}.jpg ${breakpoint}w`
        ).join(',')
    }} imgStyle={{
      transition: 'opacity 0.5s ease',
      position: aspectRatio === 0 ? 'static' : 'absolute'
    }} {...other}/>
  )
}

AssetZenImage.defaultProps = {
  quality: 70,
  srcSetBreakpoints: [480, 720, 960, 1280, 1920, 2880]
};

AssetZenImage.propTypes = {
  
};

export default AssetZenImage;
