import React from 'react';
import Fade from 'react-reveal/Fade';
import styles from './Testimonial.module.scss';

const Testimonial = ({ data }) => {
    return (
        <Fade bottom>
            <figure className={styles.testimonial}>
                {data.frontmatter ?
                    <>
                        <p className={styles.quote}>
                            &ldquo;{data.frontmatter.quote}&rdquo;
                        </p>

                        <footer>
                            <cite className={styles.citation}>
                                <strong className={styles.name}>{data.frontmatter.title}</strong>
                                <span className={styles.jobTitle}>{data.frontmatter.jobTitle}</span>
                            </cite>
                        </footer>
                    </>
                    :
                    <p className={styles.quote}>
                        &ldquo;{data}&rdquo;
                    </p>
                }
            </figure>
        </Fade>
    )
}

Testimonial.propTypes = {

};

export default Testimonial;
